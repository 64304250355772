<template>
    <div class="eCommerce-container">
        <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
            <el-table-column label="商品信息">
                <template slot-scope="scope">
                    <div class="goods-info">
                        <div class="good-img" @click="toGoodDetail(scope.row.id)">
                            <img :src="`${hosturl}${scope.row.good_images}`" alt="">
                        </div>
                        <div class="info-right text-overflow-2" @click="toGoodDetail(scope.row.id)">{{scope.row.good_title}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="sales_price" label="销售价" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="toGoodDetail(scope.row.id)">查看详情</el-link>
                </template>
            </el-table-column>
            <template slot="empty">
                <div class="null-data">
                    <div style="display: flex;flex-direction: column;align-items: center;">
                        <img src="../../../../../assets/images/student/goods-null-data.png" alt="">
                        <span>暂无数据</span>
                    </div>
                </div>
            </template>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0 30px" v-if="infoList.length > 0"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { pricing_managementHotGoods } from '@/utils/apis.js'
    export default {
        data() {
            return {
                op_id: Number(this.$route.query.op_id) || null,
                infoList: [],
                hosturl: localStorage.getItem('hosturl'),
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                isExamOrTrain: localStorage.getItem('examId'), // 有考试， 没有训练
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            // 获取列表
            getList() {
                let params = {
                    op_id: this.$route.query.op_id,
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                pricing_managementHotGoods(params).then((res) => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                }).catch((err) => {})
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            // 去商品详情
            toGoodDetail(id) {
                let routeDate = this.$router.resolve({
                    path: '/goods/detail',
                    query:{
                        g_id: id,
                        type: 1
                    }
                })
                window.open(routeDate.href, '_blank')
            },
        }
    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }
    .goods-info {
        display: flex;
        align-items: center;
        .good-img {
            width: 80px;
            height: 80px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                max-width: 100%;
            }
        }
        .info-right {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            cursor: pointer;
        }
    }
</style>